import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isFetchingMessages } from '../modules/localization/selectors';
import useBreakpoints from './useBreakpoints';

const setData = (data, emptyItem, item) => {
  if (emptyItem) {
    data.push(null);
  } else if (item !== null) {
    data.push(item);
  }
};

export const useDataGrid = (onLoadMethod, options, isInvalid = false, isFetching = false) => {
  const {
    apartmentId = null,
    companyId = null,
    context = null,
    emptyApartment = false,
    emptyCompany = false,
    emptyGroup = false,
    emptyPhonebook = false,
    emptySite = false,
    emptyUser = false,
    filter = {},
    groupId = null,
    noContextNeeded = false,
    order = null,
    page = 1,
    phonebookId = null,
    rowsPerPage = 10,
    siteId = null,
    userId = null,
  } = options;

  const onLoad = (...params) => {
    const data = [];

    setData(data, emptyApartment, apartmentId);
    setData(data, emptyCompany, companyId);
    setData(data, emptySite, siteId);
    setData(data, emptyGroup, groupId);
    setData(data, emptyUser, userId);
    setData(data, emptyPhonebook, phonebookId);

    data.push(...params);
    onLoadMethod(...data);
  };

  useEffect(() => {
    if (emptyCompany || (!emptyCompany && companyId) || noContextNeeded) {
      onLoad(1, rowsPerPage, filter, order, context);
    }
  }, [companyId, emptyCompany, noContextNeeded]);

  useEffect(() => {
    if (isInvalid && (emptyCompany || (!emptyCompany && companyId) || noContextNeeded)) {
      onLoad(page, rowsPerPage, filter, order, context);
    }
  }, [isInvalid, emptyCompany, companyId, noContextNeeded]);

  const onSelectPage = (value) => {
    if (!isFetching) {
      onLoad(value, rowsPerPage, filter, order, context);
    }
  };
  const onRowsPerPageChange = (value) => onLoad(1, value, filter, order, context);
  const onOrderBy = (value) => onLoad(page, rowsPerPage, filter, value, context);

  return {
    onOrderBy,
    onRowsPerPageChange,
    onSelectPage,
  };
};

export const getOffset = (page, rowsPerPage) => (page && rowsPerPage ? Math.max(0, page - 1) * rowsPerPage : 0);

export const changeFilter = (onSearch, onLoadMethod, options) => (name, value) => {
  const {
    apartmentId = null,
    companyId = null,
    context = null,
    emptyApartment = false,
    emptyCompany = false,
    emptyGroup = false,
    emptySite = false,
    emptyUser = false,
    filter = {},
    groupId = null,
    include = undefined,
    noContextNeeded = false,
    order = null,
    rowsPerPage = 10,
    siteId = null,
    userId = null,
  } = options;

  const onLoad = (...params) => {
    const data = [];

    setData(data, emptyApartment, apartmentId);
    setData(data, emptyCompany, companyId);
    setData(data, emptySite, siteId);
    setData(data, emptyGroup, groupId);
    setData(data, emptyUser, userId);

    data.push(...params);
    onSearch(onLoadMethod, data);
  };

  if (emptyCompany || (!emptyCompany && companyId) || noContextNeeded) {
    onLoad(1, rowsPerPage, { ...filter, [name]: value }, order, context, include);
  }
};

export const useButtonText = (desktopText, mobileText) => {
  const { downMd, downSm } = useBreakpoints();
  const { formatMessage } = useIntl();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  return useMemo(() => {
    if (downSm) return '';
    if (downMd) return formatMessage(mobileText);
    return formatMessage(desktopText);
  }, [downMd, downSm, isLocalizationLoaded]);
};

// eslint-disable-next-line complexity
export const getDataGridSelector = (state, moduleName, section) => {
  const moduleData = get(state?.[moduleName], section) || {};
  return {
    data: moduleData.data || [],
    didInvalid: moduleData.didInvalid || false,
    filter: moduleData.filter || {},
    isFetching: moduleData.isFetching || false,
    order: moduleData.order || null,
    page: moduleData.page || 1,
    resultsFiltered: moduleData.resultsFiltered || 0,
    resultsTotal: moduleData.resultsTotal || 0,
    rowsPerPage: moduleData.rowsPerPage || 10,
  };
};

// no tests - for development
export const dataGridProps = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
});
